.treeview {
	margin: 10px 0 0 20px;
}

.treeview, .treeview ul { 
	list-style: none;
	overflow: hidden;
}

.treeview li {        
	padding: 2px 0 2px 16px;
}

.treeview li.last {
	background-position: 0 -1766px;
}

.treeview li > input {
	height: 16px;
	width: 16px;
	/* hide the inputs but keep them in the layout with events (use opacity) */
	opacity: 0;
	filter: alpha(opacity=0); /* internet explorer */ 
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(opacity=0)"; /*IE8*/
}

.treeview li > label {
	background: url(/plugins/treeview-cb/gr_custom-inputs.png) 0 -1px no-repeat;
	/* move left to cover the original checkbox area */
	margin-left: -20px;
	/* pad the text to make room for image */
	padding-left: 20px;
}

/* Unchecked styles */
.treeview .custom-unchecked {
	background-position: 0 -1px;
}
.treeview .custom-unchecked:hover {
	background-position: 0 -21px;
}
/* Checked styles */
.treeview .custom-checked { 
	background-position: 0 -81px;
}
.treeview .custom-checked:hover { 
	background-position: 0 -101px; 
}

/* Indeterminate styles */

.treeview .custom-indeterminate { 
	background-position: 0 -141px; 
}
.treeview .custom-indeterminate:hover { 
	background-position: 0 -121px; 
}   
.treeview .treeview-half {
	float: left;
	width: 50%;
}
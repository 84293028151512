@-webkit-keyframes vex-flyin {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes vex-flyin {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes vex-flyout {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px); } }

@keyframes vex-flyout {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    transform: translateY(-40px); } }

@-webkit-keyframes vex-pulse {
  0% {
    box-shadow: inset 0 0 0 300px transparent; }
  70% {
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25); }
  100% {
    box-shadow: inset 0 0 0 300px transparent; } }

@keyframes vex-pulse {
  0% {
    box-shadow: inset 0 0 0 300px transparent; }
  70% {
    box-shadow: inset 0 0 0 300px rgba(255, 255, 255, 0.25); }
  100% {
    box-shadow: inset 0 0 0 300px transparent; } }

.vex.vex-theme-os {
  padding-top: 160px;
  padding-bottom: 160px; }
  .vex.vex-theme-os.vex-closing .vex-content {
    -webkit-animation: vex-flyout .5s forwards;
    animation: vex-flyout .5s forwards; }
  .vex.vex-theme-os .vex-content {
    -webkit-animation: vex-flyin .5s;
    animation: vex-flyin .5s; }
  .vex.vex-theme-os .vex-content {
    border-radius: 5px;
    box-shadow: inset 0 1px #a6a6a6, 0 0 0 1px rgba(0, 0, 0, 0.08);
    font-family: "Helvetica Neue", sans-serif;
    border-top: 20px solid #bbb;
    background: #f0f0f0;
    color: #444;
    padding: 1em;
    position: relative;
    margin: 0 auto;
    max-width: 100%;
    width: 450px;
    font-size: 1.1em;
    line-height: 1.5em; }
    .vex.vex-theme-os .vex-content h1, .vex.vex-theme-os .vex-content h2, .vex.vex-theme-os .vex-content h3, .vex.vex-theme-os .vex-content h4, .vex.vex-theme-os .vex-content h5, .vex.vex-theme-os .vex-content h6, .vex.vex-theme-os .vex-content p, .vex.vex-theme-os .vex-content ul, .vex.vex-theme-os .vex-content li {
      color: inherit; }
  .vex.vex-theme-os .vex-close {
    border-radius: 0 5px 0 0;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer; }
    .vex.vex-theme-os .vex-close:before {
      border-radius: 3px;
      position: absolute;
      content: "\00D7";
      font-size: 26px;
      font-weight: normal;
      line-height: 31px;
      height: 30px;
      width: 30px;
      text-align: center;
      top: 3px;
      right: 3px;
      color: #bbb;
      background: transparent; }
    .vex.vex-theme-os .vex-close:hover:before, .vex.vex-theme-os .vex-close:active:before {
      color: #777;
      background: #e0e0e0; }
  .vex.vex-theme-os .vex-dialog-form .vex-dialog-message {
    margin-bottom: .5em; }
  .vex.vex-theme-os .vex-dialog-form .vex-dialog-input {
    margin-bottom: 1em; }
    .vex.vex-theme-os .vex-dialog-form .vex-dialog-input select, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input textarea, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="date"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="datetime"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="datetime-local"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="email"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="month"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="number"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="password"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="search"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="tel"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="text"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="time"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="url"], .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="week"] {
      border-radius: 3px;
      background: #fff;
      width: 100%;
      padding: .25em .67em;
      border: 0;
      font-family: inherit;
      font-weight: inherit;
      font-size: inherit;
      min-height: 2.5em;
      margin: 0 0 .25em; }
      .vex.vex-theme-os .vex-dialog-form .vex-dialog-input select:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input textarea:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="date"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="datetime"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="datetime-local"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="email"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="month"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="number"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="password"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="search"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="tel"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="text"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="time"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="url"]:focus, .vex.vex-theme-os .vex-dialog-form .vex-dialog-input input[type="week"]:focus {
        box-shadow: inset 0 0 0 1px #3288e6;
        outline: none; }
  .vex.vex-theme-os .vex-dialog-form .vex-dialog-buttons {
    *zoom: 1; }
    .vex.vex-theme-os .vex-dialog-form .vex-dialog-buttons:after {
      content: "";
      display: table;
      clear: both; }
  .vex.vex-theme-os .vex-dialog-button {
    border-radius: 3px;
    border: 0;
    float: right;
    margin: 0 0 0 .5em;
    font-family: inherit;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-size: .8em;
    line-height: 1em;
    padding: .75em 2em; }
    .vex.vex-theme-os .vex-dialog-button.vex-last {
      margin-left: 0; }
    .vex.vex-theme-os .vex-dialog-button:focus {
      -webkit-animation: vex-pulse 1.1s infinite;
      animation: vex-pulse 1.1s infinite;
      outline: none; }
      @media (max-width: 568px) {
        .vex.vex-theme-os .vex-dialog-button:focus {
          -webkit-animation: none;
          animation: none; } }
    .vex.vex-theme-os .vex-dialog-button.vex-dialog-button-primary {
      background: #3288e6;
      color: #fff; }
    .vex.vex-theme-os .vex-dialog-button.vex-dialog-button-secondary {
      background: #e0e0e0;
      color: #777; }

.vex-loading-spinner.vex-theme-os {
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.2), 0 0 0.5em rgba(0, 0, 0, 0.2);
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.2);
  width: 0;
  height: 0;
  border: 1.2em solid #bbb;
  border-top-color: #f0f0f0;
  border-bottom-color: #f0f0f0; }
